<template>
  <v-container fluid>
    <!-- {{ mapMarkers }} -->
    <section>
      <l-map
        class="VueLeafMap"
        :zoom="zoom"
        :center="MapCenter"
        :bounds="bounds"
      >
        <!-- :bounds="bounds" -->
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <!-- Markers  -->
        <template>
          <!-- Start and End Marker -->
          <l-marker
            v-for="(points, i) in mapMarkers"
            :key="i"
            :lat-lng="points"
          >
            <l-icon v-if="i == 0">
              <v-icon
                color="#000"
                x-large
              >
                mdi-google-maps
              </v-icon>
            </l-icon>
            <l-icon v-else-if="i == mapMarkers.length - 1">
              <Icons
                v-if="isDepot"
                name="marker-end"
              />
              <Icons
                v-else
                :name="`marker-${i}`"
                variant="marker"
              />
            </l-icon>
            <l-icon v-else>
              <Icons
                :name="`marker-${i}`"
                variant="marker"
              />
            </l-icon>
          </l-marker>
        </template>

        <!-- Markers  -->
        <!-- Polyline -->
        <l-polyline
          :lat-lngs="routeCoordinates"
          :color="'green'"
        />

        <!-- Polyline -->
      </l-map>
    </section>
  </v-container>
</template>
<script>
import { latLngBounds } from "leaflet";

import {
  LMap,
  LTileLayer,
  //   LControl,
  LMarker,
  LPolyline,
  LIcon,
  //   LPopup,
} from "vue2-leaflet";
import { mapGetters } from "vuex";
export default {
  components: {
    LMap,
    LTileLayer,
    // LControl,
    LMarker,
    LIcon,
    LPolyline,
    Icons: () => import("@/components/base/icons.vue"),

    // LPopup,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      //
      mapMarkers: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      routePlan: "routeListing/getRoutePlan",
    }),
    isDepot() {
      return this.routePlan?.return_to_depot;
    },

    bounds() {
      let arr = [];
      if (this.routeCoordinates.length > 0) {
        // const routeLength = this.routeCoordinates.length - 1;
        // arr.push(this.routeCoordinates[0]);
        arr.push(this.routeCoordinates);
      }
      //   defual location when nothing is selected
      else {
        arr.push([24.8785, 67.0641]);
      }
      return latLngBounds(arr);
    },

    routeCoordinates() {
      const mrk = [];
      let arr = [];
      if (this.routePlan?.id) {
        let pnt = this.routePlan.api_response[0].points;

        pnt.forEach((el) => {
          el.coordinates.forEach((coor) => {
            arr.push([coor[1], coor[0]]);
          });
        });
        // const coor = [
        //   ...this.routePlan?.api_response[0]?.points[0]?.coordinates,
        // ];
        // coor.forEach((el) => {
        //   arr.push([el[1], el[0]]);
        // });
        const m = this.routePlan.api_response[0].activities.map(
          (r) => r.address
        );
        m.forEach((el) => {
          mrk.push([el.lat, el.lon]);
        });
        // const m = [...this.routePlan?.api_response[0]?.activities];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mapMarkers = mrk;
      }

      return arr;
    },

    MapCenter() {
      //   return this.routeCoordinates[0] ?? [24.8785, 67.0641];
      return [24.8785, 67.0641];
    },
  },
  beforeDestroy() {
    this.$store.commit("routeListing/SET_ROUTE_PLAN", []);
  },
  async mounted() {},
  methods: {
    fetchData() {},
  },
};
</script>
<style lang="sass" scoped>
.VueLeafMap
    z-index: 1 !important
    height: 250px
</style>
